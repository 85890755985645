<template>
  <div>
    <div class="hero">
      <img src="/about-us.png" alt="hero-image" />
      <b-container class="hero-text d-flex">
        <div class="w-100">
          <h1 class="mb-0 mt-sm-5 mb-3">
            <span>{{ $t("Pridajte sa k nám") }}</span> <br />
            <span>{{ $t("v misii pripraviť mladých") }}</span> <br />
            <span class="w-color-primary z-fancy-underline">
              {{ $t("for-life") }}
              <svgFancyUnderline />
            </span>
          </h1>
          <b-button
            variant="primary"
            class="a-poppins-btn -wider mt-3"
            href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0-8eb75/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t("Podporte nás") }}</b-button
          >
        </div>
      </b-container>
    </div>
    <AboutNasPribeh />
    <AboutCoSmeDosiahli />
    <AboutOcenenia />
    <AboutAkoPomoct />
    <AboutNapisaliONasSection />
  </div>
</template>

<script>
import AboutAkoPomoct from "/components/About/AboutAkoPomoct";
import AboutCoSmeDosiahli from "/components/About/AboutCoSmeDosiahli";
import AboutNapisaliONasSection from "/components/About/AboutNapisaliONasSection.vue";
import AboutNasPribeh from "/components/About/AboutNasPribeh";
import AboutOcenenia from "/components/About/AboutOcenenia";

export default {
  components: {
    AboutOcenenia,
    AboutAkoPomoct,
    AboutNapisaliONasSection,
    AboutCoSmeDosiahli,
    AboutNasPribeh,
    svgFancyUnderline: () => import("/assets/icons/fancyUnderline.svg?inline"),
  },
};
</script>

<style lang="scss" scoped>
img {
  position: absolute;
  z-index: -1;
  right: 0;

  max-width: 84vw;
}

.hero {
  margin-bottom: 176px;
}

.hero-container {
  background-image: url("/headImg.webp");
}

li {
  list-style: none;
}

@media only screen and (max-width: 991px) {
  .hero-container {
    background-size: cover;
  }

  img {
    max-width: none;
    width: 120vw;
  }

  .hero {
    margin-bottom: 40px;
  }

  .hero-text {
    padding-top: 260px;
  }
}
</style>
